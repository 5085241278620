<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="wrapper">
      <back back-url="/article-list"></back>
      <scroll
        ref="scroll"
        class="scroll"
        :data="data"
        :listen-scroll="true"
        @scroll="scroll"
        :pull-down-refresh="{threshold: 30, stop: 0}"
        @pullingDown="pullDownRefresh"
      >
        <div>
          <div class="pull-down-wrapper">
            <div class="pull-down-text" v-show="!isPullingDown">下拉即可刷新...</div>
            <div class="pull-down-text" v-show="isPullingDown">释放即可刷新...</div>
          </div>
          <article-list-list
            :article-list="articleList"
            :total="total"
            @more="more"
          />
          <van-empty v-show="showEmpty" description="暂无内容"/>
        </div>
      </scroll>
      <router-view></router-view>
    </div>
  </transition>
</template>

<script>
import Back from '@/components/back/Back'
import scroll from '@/components/scroll/scroll'
import ArticleListList from './components/List'
import { Empty, Toast } from 'vant'
import { getArticles } from '@/api/Articles'
import { mapGetters } from 'vuex'

export default {
  name: 'ArticleList',
  components: {
    Back,
    scroll,
    ArticleListList,
    VanEmpty: Empty
  },
  data () {
    return {
      data: [],
      isPullingDown: false,
      showEmpty: false,
      page: 1,
      limit: 50,
      articleList: [],
      total: 0
    }
  },
  computed: {
    ...mapGetters([
      'agencyGuid'
    ])
  },
  mounted () {
    this._getArticles()
  },
  methods: {
    async _getArticles () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      await getArticles({
        article_agency: this.agencyGuid,
        article_display_type: 1,
        page: this.page,
        limit: this.limit
      }).then(res => {
        const { code, msg, data } = res
        if (code === 200) {
          this.articleList = this.articleList.concat(data.data)
          this.total = data.total
          this.refresh()
          Toast.clear()
        } else {
          this.showEmpty = true
          Toast.fail(msg)
        }
      })
    },
    more () {
      this.page += 1
      this._getArticles()
    },
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    },
    scroll (pos) {
      if (pos.y > 30) {
        this.isPullingDown = true
      } else if (pos.y <= 0) {
        this.isPullingDown = false
      }
    },
    pullDownRefresh () {
      this.page = 1
      this.articleList.splice(0, this.articleList.length)
      this._getArticles()
    }
  }
}
</script>

<style scoped lang="stylus">
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.wrapper
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 80px
    left 0
    right 0
    bottom 0
    overflow hidden

    .pull-down-wrapper
      display flex
      justify-content center
      align-items center
      width 750px
      height 70px
      margin-top -70px

      .pull-down-text
        line-height normal
        font-size 22px
        color rgba(153, 153, 153, 1)
</style>
