<template>
  <div class="container">
    <div class="tab">
      <div class="tab-item active">
        <div class="tab-item-text">新闻公告</div>
      </div>
      <router-link tag="div" to="/home/article-list/article/agency_open_con" class="tab-item">
        <div class="tab-item-text">开放时间</div>
      </router-link>
      <router-link tag="div" to="/home/article-list/article/agency_guide" class="tab-item">
        <div class="tab-item-text">借阅指南</div>
      </router-link>
    </div>
    <div class="content">
      <router-link
        tag="div"
        :to="'/home/article-list/article/'+item.article_guid"
        class="content-item"
        v-for="(item,index) in articleList"
        :key="index"
      >
        <div class="info-round">
          <div class="round"></div>
        </div>
        <div class="content-info">
          <span class="info-type">【{{ item.article_type }}】</span>{{ item.article_title }}
        </div>
        <div class="content-time">{{ item.article_release_time }}</div>
      </router-link>
      <div class="content-more" v-show="total > articleList.length">
        <div class="more-btn" @click="more">+ 查看更多</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleListList',
  props: {
    articleList: {
      type: Array,
      default () {
        return []
      }
    },
    total: {
      type: Number,
      default: 0
    }
  },
  methods: {
    more () {
      this.$emit('more')
    }
  }
}
</script>

<style scoped lang="stylus">
.container
  display table
  width 100%

  .tab
    display flex
    align-items center
    justify-content space-around
    width 100%
    height 78px
    border: 2px solid rgba(234, 234, 234, 1)

    .tab-item
      display flex
      align-items center
      height 78px
      font-size 28px
      color rgba(51, 51, 51, 1)

      &.active
        font-size 34px
        font-weight bold
        color rgba(179, 53, 58, 1)
        border-bottom 6px solid rgba(179, 53, 58, 1)

  .content
    width 690px
    margin 22px auto
    background rgba(255, 255, 255, 1)

    .content-item
      display flex
      flex-wrap wrap
      width 650px
      margin 0 auto
      padding 30px 0
      border-bottom 1px dotted rgba(206, 206, 206, 1)

      &:nth-last-child(2)
        border-bottom none

      .info-round
        display flex
        align-items center
        justify-content center
        width 30px
        height 28px

        .round
          width 10px
          height 10px
          border-radius 10px
          background rgba(208, 208, 208, 1)

      .content-info
        width 620px
        font-size 28px
        color rgba(51, 51, 51, 1)
        line-height 40px

        .info-type
          color rgba(179, 53, 58, 1)

      .content-time
        width 100%
        text-align right
        font-size 22px
        color rgba(153, 153, 153, 1)

    .content-more
      display flex
      align-items center
      justify-content center
      width 100%
      height 110px

      .more-btn
        padding 6px 24px
        font-size 22px
        color rgba(254, 146, 41, 1)
        border 2px solid rgba(254, 146, 41, 1)
        border-radius 18px
</style>
